import { Controller } from "stimulus"
import Organization from "../lib/Organization"

export default class extends Controller {
  static targets = ["content", "checkbox"]

  connect() {
    const isOpen = localStorage.getItem('dev-feature-flags-open') === 'true'
    if (!isOpen) {
      this.contentTarget.classList.add("d-none")
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("d-none")
    localStorage.setItem('dev-feature-flags-open', !this.contentTarget.classList.contains("d-none"))
  }

  async toggleFeature(event) {
    const checkbox = event.target
    const feature = checkbox.dataset.feature
    const enabled = checkbox.checked

    if (!Organization.exists) return

    const organization_id = Organization.current.id

    try {
      const response = await fetch('/dev/feature_flags', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ feature, enabled, organization_id })
      })

      if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`)
      
      noty({
        text: `<code>${feature}</code> <strong>${enabled ? 'enabled' : 'disabled'}</strong> for ${Organization.current.name}`,
        layout: 'bottomCenter',
        type: enabled ? 'success' : 'warning'
      });
    } catch (error) {
      console.error('Error:', error)
      // Revert checkbox state on error
      checkbox.checked = !enabled
      noty({
        text: 'Error toggling feature flag',
        layout: 'bottomCenter',
        type: 'error'
      });
    }
  }
} 
