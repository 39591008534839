import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "table", "selectAllButton", "deselectAllButton", "bulk", "selectAllInfo", "infoContainer",
    "infoRows", "selectAllRows", "deselectAllRows", "search", "clearSearchButton",
    "columnFilter"
  ]

  initialize() {
    $.extend($.fn.DataTable.ext.classes, {
      sWrapper: "dataTables_wrapper"
    });
    const dtOptions = this.data.has('config') ? JSON.parse(this.data.get('config')) : {}
    this.config = $.extend(true, this.defaultOptions(), dtOptions)
    this.dataTable = $(this.tableTarget).DataTable(this.config)
    this.bulkDestroyPath = this.data.has('bulk-destroy-path') ? this.data.get('bulk-destroy-path') : ''
    this.selected = new Set()
    this.setRowsSelectable()
    this.initializeBulkTooltips()
    this.resetBulkEdit()
    this.destroyBeforeCache()
    this.hideSelectAllInfo()
  }

  destroyBeforeCache() {
    let controller = this
    document.addEventListener("turbolinks:before-cache", function () {
      controller.destroy()
    })
  }

  initializeBulkTooltips() {
    $.each(this.bulkTargets, function () {
      $(this).tooltip({
        title: 'Select one or more rows to perform this action',
        trigger: 'hover focus',
        animation: false
      })
    })
  }

  disconnect() {
    this.destroy()
  }

  destroy() {
    this.dataTable.destroy()
  }

  get controllerName() {
    return this.element.getAttribute('data-controller')
  }

  defaultOptions() {
    var controller = this
    return {
      drawCallback(settings) {
        if (controller.selectedAllData) {
          controller.currentPageRows.select()
          controller.selectAllRows()
        } else if (controller.hasInfoRowsTarget && controller.infoRowsTarget.text != '') {
          controller.deselectAllRowsPage()
        }

        $(controller.tableTarget).on('page.dt', function () {
          controller.resetBulkEdit()
        });
      },
      language: {
        paginate: {
          next: '&raquo;',
          previous: '&laquo;'
        },
        zeroRecords: function() {
          return `<p class='mt-3'>There are no items matching this criteria</p>` +
                 `<button class='mb-3 btn btn-light btn-sm' data-action='click->${controller.controllerName}#clearAllSearches'>Try clearing your search</button>`
        },
        lengthMenu: "_MENU_ rows per page",
        search: '',
        searchPlaceholder: 'Search',
        processing: `<div class="bg-white rounded text-center p-2">${window.faicon('spinner', { pulse: true, fw: true, size: '2x' })}</div>`
      },
      buttons: [],
      serverSide: true,
      processing: true,
      search: true,
      paging: true,
      pagingType: 'simple_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      ordering: false,
      info: true,
      select: {
        style: 'multi'
      },
      dom: "<'card border-0'" +
        "<'card-block p-0 pt-3'<'row-fluid'<'alert alert-info d-none'>><'row-fluid'tr>>" +
        "<'card-footer bg-white border-0 px-0'<'float-left'l><'float-right'p>>>",
      retrieve: true,
      stateSave: false,
      stateDuration: 7200, // 2 hours
      stateSaveCallback: function (settings, data) {
        controller.saveState(settings, data)
      },
      stateLoadCallback: function (settings) {
        return controller.loadState(settings);
      },
      stateLoaded: function (settings, data) {
        controller.handleStateLoaded(settings, data);
      }
    }
  }

  saveState(settings, data) {
    try {
      localStorage.setItem(this.getStateKey(settings), JSON.stringify(data));
    } catch (e) {
      if (e.name === 'QuotaExceededError') {
        this.cleanupOldStates(settings, data);
      } else {
        console.error('Error saving datatable state:', e);
      }
    }
  }

  cleanupOldStates(settings, data) {
    // Clear all existing datatable states
    Object.keys(localStorage)
      .filter(key => key.startsWith('datatables-'))
      .forEach(key => localStorage.removeItem(key));

    try {
      localStorage.setItem(this.getStateKey(settings), JSON.stringify(data));
    } catch (e) {
      console.error('Unable to store datatable state: storage full');
    }
  }

  getAllDatatableStates() {
    return Object.keys(localStorage)
      .filter(key => key.startsWith('datatables-'))
      .map(key => ({
        key: key,
        time: JSON.parse(localStorage.getItem(key)).time
      }))
      .sort((a, b) => a.time - b.time); // Sort by time ascending
  }

  getStateKey(settings) {
    return 'datatables-' + settings.sInstance;
  }

  setRowsSelectable() {
    var controller = this;
    $(controller.tableSelector + ' tbody').on('click', 'tr', function () {
      var rowId = this.id;
      var $row = $(this);

      if ($row.find('td.dataTables_empty').length > 0) {
        // this is the "empty table" message, so don't select any rows
        controller.selected = []
        $row.removeClass('selected')
        return;
      } else if (controller.selected.has(rowId)) {
        controller.selected.delete(rowId)
      } else {
        controller.selected.add(rowId)
      }
      $row.toggleClass('selected')
      controller.hideSelectAllInfo()
      controller.controlBulkEditButton(controller.selected.size)
    });
  }

  get tableSelector() {
    return `[id="${this.tableTarget.id}"]`
  }

  get recordsTotal() {
    return this.dataTable.page.info().recordsTotal
  }

  get currentPageRows() {
    return this.dataTable.rows({ page: 'current' })
  }

  get selectedRows() {
    return this.dataTable.rows({ selected: true }).ids().toArray()
  }

  selectedRowValues(key) {
    return this.dataTable.rows({ selected: true }).data().toArray().map(row => row[key])
  }

  selectAllRowsPage() {
    this.currentPageRows.select()
    this.selected = new Set(this.currentPageRows.ids().toArray())
    this.controlBulkEditButton(this.selected.size)
    this.showSelectAllInfo()
  }

  deselectAllRowsPage() {
    this.currentPageRows.deselect()
    this.resetBulkEdit()
    this.hideSelectAllInfo()
  }

  deleteAll() {
    var controller = this
    var recordCount = this.selectedAllData ? this.recordsTotal : this.selectedRows.length

    if (recordCount > 0) {
      var conf = confirm("Are you sure you want to delete " + recordCount + " record(s)?")
      if (conf) {
        $.ajax({
          url: controller.bulkDestroyPath,
          method: 'DELETE',
          data: controller.destroyAllParams,
          complete: function () {
            window.location.reload()
          }
        })
      }
    }
  }

  resetBulkEdit() {
    if (this.hasDeselectAllButtonTarget) {
      this.selected = new Set()
      this.controlBulkEditButton(0)
    }
  }

  controlBulkEditButton(rowsSelected) {
    if (rowsSelected > 0) {
      $.each(this.bulkTargets, function () {
        if ($(this).hasClass('disabled')) {
          $(this).removeClass('disabled')
          $(this).prop('disabled', false)
          $(this).tooltip('disable')
        }
      })
      this.disableDeselectAll(false)
    } else {
      $.each(this.bulkTargets, function () {
        $(this).addClass('disabled')
        $(this).prop('disabled', true)
        $(this).tooltip('toggleEnabled')
      })
      this.disableDeselectAll(true)
    }
  }

  disableDeselectAll(disable) {
    if (this.hasDeselectAllButtonTarget) {
      if(disable) {
        $(this.deselectAllButtonTarget).addClass('disabled')
      } else {
        $(this.deselectAllButtonTarget).removeClass('disabled')
      }
      $(this.deselectAllButtonTarget).prop('disabled', disable)
    }
  }

  search(event) {
    this.deselectAllRowsPage()
    this.dataTable.search(event.target.value).draw()
    if (event.target.value) {
      this.showClearSearchButton()
    } else {
      this.hideClearSearchButton()
    }
  }

  showSelectAllInfo() {
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).removeClass('d-none')
      if (this.recordsTotal > this.selectedRows.length) {
        $(this.infoRowsTarget).html('')
        $(this.infoRowsTarget).text(this.selectedRows.length + ' rows on this page are selected. ')
        $(this.selectAllRowsTarget).removeClass('d-none')
        $(this.selectAllRowsTarget).text('Select all ' + this.recordsTotal + ' rows in this table. ')
        $(this.deselectAllRowsTarget).addClass('d-none')
      } else {
        $(this.infoRowsTarget).html('')
        var alertText = 'All ' + this.recordsTotal + ' rows in this table are selected.'
        $(this.infoRowsTarget).append(alertText, ' ')
        $(this.selectAllRowsTarget).addClass('d-none')
        $(this.deselectAllRowsTarget).addClass('d-none')
      }
    }
  }

  hideSelectAllInfo() {
    this.selectedAllData = false
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).addClass('d-none')
    }
  }

  selectAllRows() {
    this.selectedAllData = true
    $(this.infoRowsTarget).text('All ' + this.recordsTotal + ' rows in this table are selected. ')
    $(this.selectAllRowsTarget).text('')
    $(this.deselectAllRowsTarget).removeClass('d-none')
  }

  deselectAllRows() {
    this.selectedAllData = false
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).addClass('d-none')
      this.currentPageRows.deselect()
      this.resetBulkEdit()
    }
  }

  filterColumn(index, value) {
    this.dataTable
      .column( index )
      .search( value )
      .draw();
  }

  get destroyAllParams() {
    var params = { 'ids': this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
    }
    return params
  }

  showClearSearchButton() {
    if (this.hasClearSearchButtonTarget) {
      this.clearSearchButtonTarget.classList.remove('d-none')
    }
  }

  hideClearSearchButton() {
    if (this.hasClearSearchButtonTarget) {
      this.clearSearchButtonTarget.classList.add('d-none')
    }
  }

  clearSearch(redraw = true) {
    if (this.hasSearchTarget) {
      this.searchTarget.value = ''
      this.dataTable.search('')
      this.hideClearSearchButton()
      if (redraw) {
        this.dataTable.draw()
      }
    }
  }

  loadState(settings) {
    const savedData = localStorage.getItem(this.getStateKey(settings));
    if (savedData) {
      return JSON.parse(savedData);
    }
    return null;
  }

  handleStateLoaded(settings, data) {
    // Handle search box
    if (this.hasSearchTarget && data.search.search && data.search.search.length > 0) {
      this.searchTarget.value = data.search.search;
      this.showClearSearchButton()
      this.highlightElement(this.searchTarget)
    }

    // Handle column filters
    if (this.hasColumnFilterTarget) {
      data.columns.forEach((column, index) => {
        if (column.search.search) {
          const columnDef = settings.aoColumns[index];
          const columnName = columnDef.data || columnDef.name;
          
          const columnFilterTarget = this.columnFilterTargets.find(target => 
            target.dataset.column === columnName
          )
          
          if (columnFilterTarget && columnFilterTarget.value !== column.search.search) {
            columnFilterTarget.value = column.search.search;
            
            // Check for selectpicker controller instead of class
            this.refreshSelectPicker(columnFilterTarget)
            this.highlightElement(columnFilterTarget)
          }
        }
      })
    }
  }

  highlightElement(element) {
    if (this.isSelectpicker(element)) {
      $(element).selectpicker('setStyle', 'border-primary text-primary font-weight-bold', 'add');
      $(element).selectpicker('refresh');
      setTimeout(() => {
        $(element).selectpicker('setStyle', 'border-primary text-primary font-weight-bold', 'remove');
        $(element).selectpicker('refresh');
      }, 3000);
    } else {
      $(element).addClass('border-primary text-primary font-weight-bold');
      setTimeout(() => {
        $(element).removeClass('border-primary text-primary font-weight-bold');
      }, 3000);
    }
  }

  clearAllSearches() {
    this.dataTable
      .columns().search('')
    
    if (this.hasColumnFilterTarget) {
      this.columnFilterTargets.forEach(target => {
        target.value = ''
        this.refreshSelectPicker(target)
      })
    }
    // Clear search box and redraw table
    this.clearSearch(false)
    this.dataTable.draw()
  }

  isSelectpicker(target) {
    return target.hasAttribute('data-controller') && 
           target.getAttribute('data-controller').includes('selectpicker');
  }

  refreshSelectPicker(target) {
    if (this.isSelectpicker(target)) {
      $(target).selectpicker('refresh');
    }
  }
}

