import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper"

export default class extends Controller {
  static targets = ["customLogoContainer", "customLogoChanged", "customLogoInput", "template", "screenshotIds", "screenshotCard", "removeLogoButton", "currentLogo"]

  initialize() {
    if (this.setValueOnInit()) {
      this.setPowerPointTemplateValue(this.data)
    }
    this.setLogoVisibility(this.getTemplateValue())
  }

  setValueOnInit() {
    return this.data.get('set-value-on-init') == 'true'
  }

  displayLogoField() {
    return this.data.get('display-logo-field') == 'true'
  }

  toggleLogo(event) {
    this.setLogoVisibility(event.currentTarget.value)
  }

  setLogoVisibility(templateValue) {
    if (this.displayLogoField()) {
      $(this.customLogoInputTarget).show();
      this.toggleCurrentLogo(false)
    } else {
      $(this.customLogoInputTarget).hide();
      this.toggleCurrentLogo(true)
    }

    if (templateValue == '') { // None
      $(this.customLogoContainerTarget).show()
    } else {
      $(this.customLogoContainerTarget).hide()
    }
  }

  setPowerPointTemplateValue(data) {
    let value = LocalStorageHelper.storedValue(data)
    if (value != null) {
      $(this.templateTarget).selectpicker('val', value)
    }
  }

  storeValue() {
    LocalStorageHelper.storeValue(this.data, this.getTemplateValue())
  }

  getTemplateValue() {
    if (this.hasTemplateTarget) {
      return this.templateTarget.value
    } else {
      return ''
    }
  }

  toggleScreenshot(event) {
    const card = event.currentTarget
    const screenshotId = card.dataset.screenshotId
    card.classList.toggle('selected')
    card.classList.toggle('border-primary', card.classList.contains('selected'))

    if (card.classList.contains('selected')) {
      // Create and append hidden input field
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = 'screenshot_ids[]'
      hiddenField.value = screenshotId
      hiddenField.dataset.screenshotId = screenshotId
      this.screenshotIdsTarget.appendChild(hiddenField)
    } else {
      // Remove hidden input field
      const existingField = this.screenshotIdsTarget.querySelector(`input[data-screenshot-id="${screenshotId}"]`)
      if (existingField) {
        existingField.remove()
      }
    }
  }

  removeCustomLogo(event) {
    event.preventDefault()

    // Hide logo preview
    this.toggleCurrentLogo(false)

    // Show file input using jQuery like setLogoVisibility
    $(this.customLogoInputTarget).show()

    // Mark as changed
    this.customLogoChangedTarget.value = true
  }

  toggleCurrentLogo(display) {
    if (this.hasCurrentLogoTarget) {
      if (display) {
        $(this.currentLogoTarget).show()
      } else {
        $(this.currentLogoTarget).hide()
      }
    }
  }
}
