import DatatablesController from "../datatables_controller"

export default class PendingSetsController extends DatatablesController {
  static targets = [ ...super.targets, "assignedFilter", "sourceFilter", "organizationFilter" ]

  filterAssigned() {
    this.filterColumn(this.reviewerColumnIndex, this.assignedFilterValue)
  }

  filterSource(){
    this.filterColumn(this.sourceColumnIndex, this.sourceFilterValue)
  }

  filterOrganization() {
    this.filterColumn(this.organizationColumnIndex, this.organizationFilterValue)
  }

  refreshData() {
    this.dataTable.ajax.reload()
  }

  get reviewerColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'reviewer')
  }

  get sourceColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'source')
  }

  get organizationColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'organization')
  }

  get assignedFilterValue() {
    return $(this.assignedFilterTarget).val()
  }

  get sourceFilterValue() {
    return $(this.sourceFilterTarget).val()
  }

  get organizationFilterValue() {
    return $(this.organizationFilterTarget).val()
  }
}
