import DatatablesController from "../datatables_controller"

export default class CampaignsController extends DatatablesController {
  static targets = [ ...super.targets, "clientFilter" ]

  download() {
    var controller = this

    if (!this.selectedAllData && (!this.selectedRows || this.selectedRows.length === 0)) {
      noty({
        text: 'Please select at least one campaign to download!'
      })
      return
    }

    if (this.selectedAllData) {
      var params = { all: true, record_count: this.recordsTotal }
    } else {
      var campaignIds = this.selectedRows
      var params = { all: false, record_count: campaignIds.length, campaign_ids: campaignIds }
    }

    $.ajax({
      url: Routes.new_organization_campaign_download_path(App.currentOrganizationId()),
      method: 'GET',
      data: params,
      complete: function () {
        controller.deselectAllRowsPage()
      }
    })
  }

  filterClient() {
    this.filterColumn(this.clientColumnIndex, this.clientFilterValue)
  }

  get clientColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'client_id')
  }

  get clientFilterValue() {
    return $(this.clientFilterTarget).val()
  }
}
