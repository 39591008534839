import DatatablesController from "../datatables_controller"

export default class ScreenshotsController extends DatatablesController {
  createPresentation() {
    var controller = this
    var campaignId = this.data.get('campaign-id')

    if (this.selectedAllData) {
      var params = { all: true, record_count: this.recordsTotal }
      params['screenshot_set_ids'] = this.screenshotSetIds
    } else {
      var params = {
        all: false,
        record_count: this.selectedRows.length,
        screenshot_ids: this.selectedRows
      }
    }

    $.ajax({
      data: params,
      url: Routes.new_organization_campaign_presentation_path(App.currentOrganizationId(), campaignId),
      method: 'GET',
      complete: function () {
        controller.deselectAllRowsPage()
      }
    })
  }

  regenerate() {
    var campaignId = this.data.get('campaign-id')
    var recordCount = this.selectedAllData ? this.recordsTotal : this.selectedRows.length
    var params = { 'screenshot_set_ids': this.screenshotSetIds }

    if (this.selectedAllData) {
      params = { all: true }
    } else {
      params['screenshot_ids'] = this.selectedRows
    }

    var conf = confirm("This will replace existing images for the selected screenshots. Are you sure you want to regenerate " + recordCount + " screenshot(s)?")
    if (conf) {
      $.ajax({
        url: Routes.regenerate_organization_campaign_screenshot_sets_path(App.currentOrganizationId(), campaignId),
        method: 'PUT',
        data: params,
        complete: function () {
          window.location.reload()
        }
      })
    }
  }

  download() {
    var controller = this
    var campaignId = this.data.get('campaign-id')

    if (this.selectedAllData) {
      var params = {
        all: true,
        record_count: this.recordsTotal,
        screenshot_set_ids: this.screenshotSetIds
      }
    } else {
      var params = {
        all: false,
        record_count: this.selectedRows.length,
        screenshot_set_ids: this.selectedScreenshotSetIds,
        screenshot_ids: this.selectedRows
      }
    }

    $.ajax({
      url: Routes.draft_organization_campaign_screenshot_downloads_path(App.currentOrganizationId(), campaignId),
      method: 'POST',
      data: params,
      complete: function () {
        controller.deselectAllRowsPage()
      }
    })
  }

  get selectedScreenshotSetIds() {
    return $.uniqueSort(this.selectedRowValues('set_id'))
  }

  get screenshotSetIds() {
    if (this.data.has('screenshot-set-ids')) {
      return JSON.parse(this.data.get('screenshot-set-ids'))
    }
  }

  get destroyAllParams() {
    var params = { 'ids': this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
      params['screenshot_set_ids'] = this.screenshotSetIds
    }
    return params
  }
}
